import React from 'react';
import SpacingContent from './SpacingContent';
import Section from './Section';
import {
    Avatar,
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Gallaries from './Gallaries';
import { ArrowRightAltRounded } from '@mui/icons-material';
import { colors } from '../assets/utils/colors';
import { Link } from 'react-router-dom';
import g16 from '../assets/media/images/gallaries/main.jpg';
// import gvideo1 from '../assets/media/images/gallaries/videos/v1.mp4';
// import FsLightbox from 'fslightbox-react';

const EventGallaries = () => {
    // const [toggler, setToggler] = React.useState(false);
    const deviceWidth = useMediaQuery('(min-width:600px)');
    return (
        <>
            {/* <FsLightbox toggler={toggler} sources={[gvideo1]} /> */}
            <SpacingContent maxPadding={3}>
                <Section
                    head={'Events gallaries'}
                    subHead={'We believe in creativity and talents'}
                >
                    <Grid container spacing={4} justifyContent={'center'}>
                        <Grid item sm={5} xs={12} sx={{ mt: { md: 0, xs: 3 } }}>
                            <Box
                                sx={{
                                    'position': 'relative',
                                    'display': 'flex',
                                    'justifyContent': 'center',
                                    'alignItems': 'center',
                                    ':before': {
                                        content: "''",
                                        height: { md: 400, xs: 250 },
                                        width: { md: 150, xs: 25 },
                                        position: 'absolute',
                                        left: -20,
                                        top: -20,
                                        borderTop: `10px solid ${colors.bgColor6}`,
                                        borderLeft: `10px solid ${colors.bgColor6}`,
                                        borderTopLeftRadius: { md: 110, xs: 0 },
                                    },
                                }}
                            >
                                <Avatar
                                    src={g16}
                                    variant="square"
                                    sx={{
                                        width: '100%',
                                        height: { md: 500, xs: 250 },
                                        borderTopLeftRadius: deviceWidth
                                            ? 100
                                            : 0,
                                    }}
                                />
                                {/* <IconButton
                                    size="small"
                                    sx={{
                                        'position': 'absolute',
                                        'border': `5px solid ${colors.bgColor6}`,
                                        'bgcolor': colors.bgColor3,
                                        'borderRadius': `100px !important`,
                                        'transition': `.3s all ease-in-out`,
                                        '& .play': {
                                            transition: `.3s all ease-in-out`,
                                        },
                                        ':hover': {
                                            'bgcolor': colors.bgColor3,
                                            'transition': `.3s all ease-in-out`,
                                            '& .play': {
                                                fontSize: { md: 95, xs: 75 },
                                                transform: `rotate(360deg)`,
                                                transition: `.3s all ease-in-out`,
                                            },
                                        },
                                    }}
                                    onClick={() => setToggler(!toggler)}
                                >
                                    <PlayCircle
                                        className="play"
                                        color="warning"
                                        sx={{ fontSize: { md: 90, xs: 70 } }}
                                    />
                                </IconButton> */}
                            </Box>
                        </Grid>
                        <Grid item sm={7} xs={12}>
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1,
                                    px: { md: 4, xs: 0 },
                                }}
                            >
                                <Box sx={{ width: '100%' }}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: { md: 20, xs: 18 },
                                                textTransform: 'uppercase',
                                                fontWeight: 'bold',
                                                color: colors.secondary,
                                                textAlign: {
                                                    md: 'left',
                                                    xs: 'center',
                                                },
                                            }}
                                        >
                                            See what we can get done for your
                                            event
                                        </Typography>
                                        <Typography
                                            sx={{
                                                py: 1,
                                                fontSize: 15,
                                                opacity: 0.8,
                                            }}
                                        >
                                            We build trust through responsible
                                            actions
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Gallaries />
                                    </Box>
                                    <Box>
                                        <Link to={'/gallaries'}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    'py': 1,
                                                    'px': 4,
                                                    'fontWeight': 'bold',
                                                    'fontSize': {
                                                        md: 14,
                                                        xs: 12,
                                                    },
                                                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                                    'transition': `.5s all ease-in-out`,
                                                    ':hover': {
                                                        transform: `scale(1.01)`,
                                                        transition: `.5s all ease-in-out`,
                                                    },
                                                }}
                                                endIcon={
                                                    <ArrowRightAltRounded />
                                                }
                                            >
                                                More events Gallaries
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Section>
            </SpacingContent>
        </>
    );
};

export default EventGallaries;
