import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';

const EventWidget2 = ({ service }) => {
    return (
        <>
            <Box sx={{ display: 'flex', my: 2, alignItems: 'center' }}>
                <Typography
                    sx={{
                        height: 50,
                        width: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 20,
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        mr: 2,
                        borderTopLeftRadius: 15,
                        borderBottomRightRadius: 15,
                        opacity: 0.8,
                        color: colors.textColor,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                    }}
                >
                    {service.id}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { md: 18, xs: 15 },
                        color: colors.textColor,
                        opacity: 0.8,
                    }}
                >
                    {service.service}
                </Typography>
            </Box>
        </>
    );
};

export default EventWidget2;
