import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { ReadMore } from '@mui/icons-material';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { gallariesSelector } from '../state/features/selectors';
import GallaryView from './GallaryView';
import { isEmpty, take } from 'lodash';

export default function TitlebarImageList() {
    // ############### Comp State ####################
    const [toggler, setToggler] = React.useState(false);
    const [gallaryView, setGallaryView] = React.useState(null);
    const [showGallaryViewer, setShowGallaryViewer] = React.useState('none');

    // ############### CONST ####################
    const deviceWidth = useMediaQuery('(min-width:600px)');

    // ############### Redux state ##############
    const { gallaries } = useSelector(gallariesSelector);

    // ############## FUNC ######################
    const handleGallaryView = (gallary) => {
        setToggler(!toggler);
        setShowGallaryViewer('block');
        setGallaryView(gallary);
    };

    // ############## useEffect #################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            isEmpty(gallaryView) && setShowGallaryViewer('none');
        }
        return () => {
            isSubscribed = false;
        };
    }, [gallaryView]);

    return (
        <>
            <Box sx={{ display: showGallaryViewer }}>
                <GallaryView toggler={toggler} gallary={gallaryView} />
            </Box>

            <ImageList gap={deviceWidth ? 15 : 15} cols={deviceWidth ? 3 : 2}>
                {take(gallaries, 6)?.map((gallary, index) => (
                    <ImageListItem
                        key={index}
                        sx={{
                            borderRadius: { md: 2, xs: 2 },
                            overflow: 'hidden',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleGallaryView(gallary)}
                    >
                        <img
                            src={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                            alt={gallary.event}
                            loading="lazy"
                            style={{ height: deviceWidth ? 150 : 120 }}
                        />
                        <ImageListItemBar
                            title={
                                <Typography noWrap sx={{ fontSize: 13 }}>
                                    {gallary.event}
                                </Typography>
                            }
                            subtitle={`@ ${gallary.year}`}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    onClick={() => handleGallaryView(gallary)}
                                >
                                    <ReadMore color="secondary" />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    );
}
