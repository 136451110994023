import { systemConfigSelector } from '../state/features/selectors';
import { useSelector } from 'react-redux';

const useColor = () => {
    // ######### Inner states #############
    let color = null;

    // ######### Redux states #############
    const { darkMode } = useSelector(systemConfigSelector);

    const lightColor = {
        primary: '#bde0fe',
        secondary: '#fca311',
        bgColor1: '#D7CCFB',
        bgColor2: '#E0D7FF',
        bgColor3: '#ECE7FF',
        bgColor4: '#fff4e3',
        yellow: '#FFD900',
        green: '#00EE76',
        red: '#F43249',
        gray: '#D9D9D9',
        blue: '#027BBD',
    };

    const darkColor = {
        primary: '#fff',
        secondary: '#fca311',
        bgColor1: '#0d1b2a',
        bgColor2: '#0d1b2a',
        bgColor3: '#0d1b2a',
        bgColor4: '#0d1b2a',
        yellow: '#FFD900',
        green: '#00EE76',
        red: '#F43249',
        gray: '#D9D9D9',
        blue: '#027BBD',
    };

    if (darkMode) {
        color = darkColor;
    } else {
        color = lightColor;
    }
    return color;
};

export default useColor;
