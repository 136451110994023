export const colors = {
    primary: '#bde0fe',
    secondary: '#fca311',
    textColor: "#14213d",
    bgColor1: '#ecf6ff',
    bgColor2: '#c5e4fe',
    bgColor3: '#caf0f8',
    bgColor4: '#fffaf2',
    bgColor5: '#feedd0',
    bgColor6: '#fee1b0',
}