import { createSlice } from '@reduxjs/toolkit';
import { clients } from '../../dummyStates/clients';

// => initialState
const initialState = {
    clients: clients,
};
export const clientsSlice = createSlice({
    name: 'clientsSlice',
    initialState,
});

export default clientsSlice.reducer;
