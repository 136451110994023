import React from 'react';
import { Grid } from '@mui/material';

const SpacingContent = ({ maxPadding, children }) => {
    return (
        <>
            <Grid
                container
                justifyContent={'center'}
                sx={{ p: { md: 0, xs: maxPadding } }}
            >
                <Grid item sm={11} xs={12}>
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default SpacingContent;
