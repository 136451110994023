import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { gallaryEndpoints } from "./gallaryEndpoints";

export const gallaryAPI = createApi({
    reducerPath: "gallaryAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        getPublicGallaries: builder.query({
            query: () => `${gallaryEndpoints.GET_ACTIVE_GALLERIES}`,
        }),
    }),
});

export const { useGetPublicGallariesQuery, useLazyGetPublicGallariesQuery } =
    gallaryAPI;
