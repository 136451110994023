import iale from '../../assets/media/images/clients/iale.png';
import icn from '../../assets/media/images/clients/icn.png';
import mtunzi from '../../assets/media/images/clients/mtunzi.png';
import afya from '../../assets/media/images/clients/afya.png';
import nic from '../../assets/media/images/clients/nic.png';
import radio1 from '../../assets/media/images/clients/radio1.png';
import radio2 from '../../assets/media/images/clients/radio2.png';

export const clients = [
    {
        id: 1,
        client: '11th IALE 2023 World Conference',
        year: '2023',
        logo: iale,
        url: 'https://iale2023.org/',
    },
    {
        id: 2,
        client: 'I Chance Nations Tanzania',
        year: '2022/2023',
        logo: icn,
        url: 'https://www.nictanzania.co.tz',
    },
    {
        id: 3,
        client: 'Mjue Mtunzi Season ii: Book launch & Award ceremony',
        year: '2022',
        logo: mtunzi,
        url: '',
    },
    {
        id: 4,
        client: 'Afya Intelligence: team building',
        year: '2022',
        logo: afya,
        url: 'https://afyaintelligence.co.tz/',
    },
    {
        id: 6,
        client: 'St. Fillister Pre & Primary School; Fundraising',
        year: '2022',
        logo: '',
        url: '',
    },
    {
        id: 7,
        client: 'NIC team building (helping staff)',
        year: '2021',
        logo: nic,
        url: '',
    },
    {
        id: 8,
        client: 'St. Joseph Cathedral; Fundraising',
        year: '2020',
        logo: '',
        url: '',
    },
    {
        id: 9,
        client: 'WFRM: World & Continental Concerences',
        year: '2018',
        logo: radio2,
        url: '',
    },
    {
        id: 11,
        client: 'WFRM Africa team capacity building',
        year: '2017 to date',
        logo: radio1,
        url: '',
    },
];
