import { Box, Typography } from "@mui/material";
import React from "react";
import Countdown from "react-countdown";
import TimerCountWidget from "./widgets/TimerCountWidget";
import { colors } from '../assets/utils/colors';

const EventCountDown = () => {
    const Completionist = () => (
        <Box
            sx={{
                p: 1,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
            }}
        >
            <Typography
                sx={{
                    p: 1,
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: "bold",
                }}
            >
                10 June 2024
            </Typography>
            <Typography
                sx={{
                    fontSize: 14,
                    textAlign: "center",
                    fontWeight: "bold",
                }}
            >
                Voting has closed!
            </Typography>
        </Box>
    );

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                    gap={2}
                >
                    <TimerCountWidget item={"Days"} value={days} />
                    <TimerCountWidget item={"Hours"} value={hours} />
                    <TimerCountWidget item={"Minutes"} value={minutes} />
                    <TimerCountWidget item={"Seconds"} value={seconds} />
                </Box>
            );
        }
    };
    return (
        <div>
            <Countdown date={Date.parse(`2024-08-27`)} renderer={renderer} />
        </div>
    );
};

export default EventCountDown;
