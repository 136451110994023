import { createSlice } from "@reduxjs/toolkit";
// import { gallaries, gallariesHome } from "../../dummyStates/gallaries";

// => initialState
const initialState = {
    gallaries: null,
};
export const gallariesSlice = createSlice({
    name: "gallariesSlice",
    initialState,
    reducers: {
        setGallaries: (state, actions) => {
            state.gallaries = actions.payload;
        },
    },
});

export const { setGallaries } = gallariesSlice.actions;
export default gallariesSlice.reducer;
