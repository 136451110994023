import React from 'react';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import event from '../assets/media/images/banner.jpeg';
import SpacingContent from './SpacingContent';
import { colors } from '../assets/utils/colors';
import { ReadMore } from '@mui/icons-material';
import logo from '../assets/media/images/logo2.png';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <>
            <Box
                sx={{
                    zIndex: 500,
                    height: { md: 500, xs: '70vh' },
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    borderBottomLeftRadius: 50,
                    borderBottomRightRadius: 50,
                    overflow: 'hidden',
                    borderBottom: {
                        md: 'none',
                        xs: `2px solid ${colors.secondary}`,
                    },
                }}
            >
                <Grid container>
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 500,
                            }}
                        >
                            <Avatar
                                src={logo}
                                variant="square"
                                sx={{ height: 400, width: 250, opacity: 0.05 }}
                            />
                        </Box>
                        <SpacingContent>
                            <Box
                                sx={{
                                    height: { md: 500, xs: '70vh' },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    backgroundImage: {
                                        md: 'none',
                                        // xs: `url(${event})`,
                                    },
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundAttachment: 'fixed',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 0,
                                        left: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        bgcolor: {
                                            md: 'transparent',
                                            xs: `rgb(236, 246, 255, .9)`,
                                        },
                                        padding: { md: 0, xs: 4 },
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 30,
                                                fontWeight: 'bold',
                                                color: colors.textColor,
                                                textTransform: 'uppercase',
                                                opacity: 0.8,
                                            }}
                                        >
                                            The V-Event Management team
                                        </Typography>
                                    </Box>
                                    <Box sx={{ my: 2 }}>
                                        <Typography>
                                            Comprised of a group of highly{' '}
                                            <strong
                                                style={{
                                                    color: colors.secondary,
                                                }}
                                            >
                                                motivated, dedicated
                                                professionals,
                                            </strong>{' '}
                                            who bring together a wide variety of
                                            specialized skills and knowledge in
                                            order to meet the changing needs of
                                            our clients.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mt: 2, zIndex: 500 }}>
                                        <Link to={'/about-us'}> 
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    'py': 1.5,
                                                    'px': 4,
                                                    'fontWeight': 'bold',
                                                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                                    'transition': `.5s all ease-in-out`,
                                                    ':hover': {
                                                        transform: `scale(1.01)`,
                                                        transition: `.5s all ease-in-out`,
                                                    },
                                                }}
                                                endIcon={<ReadMore />}
                                            >
                                                Who Is V-Event
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </SpacingContent>
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        sx={{ display: { md: 'block', xs: 'none' } }}
                    >
                        <Box>
                            <Avatar
                                src={event}
                                variant="square"
                                sx={{
                                    width: '100%',
                                    height: 500,
                                    borderTopLeftRadius: 50,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Banner;
