import React from 'react';
import SpacingContent from './SpacingContent';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { colors } from '../assets/utils/colors';
import { useSelector } from 'react-redux';
import { partinersSelector } from '../state/features/selectors';

const OurPartiners = () => {
    const { partiners } = useSelector(partinersSelector);

    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    py: 0,
                }}
            >
                <SpacingContent maxPadding={2}>
                    <Grid container justifyContent={'space-around'}>
                        <Grid item sm={12} xs={12}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: { md: 16, xs: 14 },
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: colors.secondary,
                                        mb: 4,
                                    }}
                                >
                                    We already partiner with the following
                                    organizations
                                </Typography>
                            </Box>
                        </Grid>
                        {partiners?.map((partiner) => (
                            <a
                                href={partiner.url}
                                target="_blank"
                                key={partiner.id}
                                rel="noreferrer"
                            >
                                <Grid item sm={3} xs={4}>
                                    <Avatar
                                        src={partiner.logo}
                                        variant="square"
                                        sx={{
                                            width: {
                                                md: partiner.width
                                                    ? partiner.width
                                                    : 100,
                                                xs: 90,
                                            },
                                            height: { md: 100, xs: 90 },
                                            img: {
                                                width: {
                                                    md: partiner.width
                                                        ? partiner.width
                                                        : 100,
                                                    xs: 90,
                                                },
                                                height: { md: 100, xs: 90 },
                                                objectFit: 'contain',
                                            },
                                        }}
                                    />
                                </Grid>
                            </a>
                        ))}
                    </Grid>
                </SpacingContent>
            </Box>
        </>
    );
};

export default OurPartiners;
