import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import event from '../../assets/media/images/capacity.jpeg';

const PageLayout = ({ head, subHead, children }) => {
    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            height: {md: 220, xs: 200},
                            overflow: 'hidden',
                            position: 'relative',
                            backgroundPosition: 'center',
                            backgroundSize: {md:'100% 1000px', xs:'100% 500px'},
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${event})`,
                            borderBottomLeftRadius: { md: 0, xs: 0 },
                            borderBottomRightRadius: { md: 0, xs: 0 },
                        }}
                    >
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                background: `linear-gradient(180deg, rgb(129, 196, 253, .6), ${colors.bgColor4})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { md: 25, xs: 18 },
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    color: colors.textColor,
                                    opacity: 0.8,
                                    textTransform: 'uppercase',
                                    position: 'relative',
                                }}
                            >
                                {head}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    textAlign: 'center',
                                    color: colors.textColor,
                                    opacity: 0.8,
                                }}
                            >
                                {subHead}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12}>
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default PageLayout;
