import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../baseUrl/baseURL';

export const eventTypeAPI = createApi({
    reducerPath: 'eventTypeAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        getPublicEventTypes: builder.query({
            query: ``,
        }),
    }),
});

export const {useGetPublicEventTypesQuery} = eventTypeAPI;
