import React, { useRef } from 'react';
import { Swiper } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import { Box, Button, Grid } from '@mui/material';
import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import { colors } from '../../assets/utils/colors';

const CustomSwiper = ({ conHeight, bgColor, children }) => {
    const sliderRef = useRef();
    return (
        <Box
            sx={{
                width: { md: '100%', xs: '90vw' },
                position: 'relative',
                height: conHeight ? conHeight : 500,
                bgcolor: bgColor ? bgColor : 'none',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item sm={12} xs={12}>
                        <Swiper
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                            modules={[Pagination, Navigation]}
                            pagination={{
                                el: '.pagination',
                                type: 'custom',
                                renderCustom: function (
                                    swiper,
                                    current,
                                    total,
                                ) {
                                    return current + '/' + total;
                                },
                            }}
                            // onSlideChange={() => console.log('slide change')}
                            effect={'cube'}
                            onSwiper={(it) => (sliderRef.current = it)}
                            breakpoints={{
                                // when window width is >= 320px
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                },
                                // when window width is >= 480px
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                },
                                // when window width is >= 640px
                                640: {
                                    slidesPerView: 4,
                                    spaceBetween: 24,
                                },
                            }}
                        >
                            {children}
                        </Swiper>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                mt: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    height: 30,
                                    width: 100,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 3,
                                    mr: 2,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                }}
                                className="pagination"
                            ></Box>

                            <Button
                                variant="outlined"
                                size="small"
                                color='secondary'
                                sx={{ mr: 2 }}
                                onClick={() => sliderRef.current?.slidePrev()}
                            >
                                <ArrowCircleLeft />
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                color='secondary'
                                onClick={() => sliderRef.current?.slideNext()}
                            >
                                <ArrowCircleRight />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CustomSwiper;
