import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import HomeSidebar from "../../components/HomeSidebar";
import HomeTopbar from "../../components/HomeTopbar";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import useRTK from "../../hooks/useRTK";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const HomeLayout = ({ children }) => {
    // ############# Const ##############
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { pathname } = useLocation();
    const RTK = useRTK();

    // ############# FUNC ##############
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // ############## RTK #################
    RTK.useRTKMainData();

    return (
        <Box>
            {/* ############### topbar ############### */}
            <HomeTopbar
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
            />

            {/* ############### sidebar ###############*/}
            <HomeSidebar mobileOpen={mobileOpen} />

            {/* ############### content ###############*/}
            <Box component="main">
                <DrawerHeader />

                {children}

                {/* ############### Footer ###############*/}
                <Footer />
            </Box>
        </Box>
    );
};

export default HomeLayout;
