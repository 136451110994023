import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";

const TimerCountWidget = ({ item, value }) => {
    return (
        <>
            <Box
                sx={{
                    height: `100%`,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    p: 1,
                    borderRadius: { md: 5, sm: 5, xs: 2.5 },
                    // background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                }}
                className="animated-bg"
            >
                <Typography
                    sx={{
                        fontSize: { md: 30, sm: 30, xs: 25 },
                        fontWeight: "bold",
                    }}
                >
                    {value}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { md: 13, sm: 13, xs: 11 },
                        fontWeight: "bold",
                    }}
                    noWrap
                >
                    {item}
                </Typography>
            </Box>
        </>
    );
};

export default TimerCountWidget;
