import { createSlice } from '@reduxjs/toolkit';
import { partiners } from '../../dummyStates/partiners';

// => initialState
const initialState = {
    partiners: partiners,
};
export const partinersSlice = createSlice({
    name: 'partinersSlice',
    initialState,
});

export default partinersSlice.reducer;
