import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemButton,
    Stack,
    styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../assets/media/images/logo2.png';
import { colors } from '../assets/utils/colors';
import SpacingContent from './SpacingContent';
import { Segment, Window } from '@mui/icons-material';
import { Link, NavLink } from 'react-router-dom';

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: colors.primary,
    boxShadow: 'none !important',
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const HomeTopbar = ({ mobileOpen, handleDrawerToggle }) => {
    const links = [
        {
            id: 1,
            title: 'V-Event',
            icon: <Window className="icon" />,
            url: '/',
        },
        {
            id: 2,
            title: 'Our services',
            url: '/services',
        },
        {
            id: 3,
            title: 'Event types',
            url: '/event-types',
        },
        {
            id: 4,
            title: 'Our clients',
            url: '/clients',
        },
        {
            id: 5,
            title: 'Events gallery',
            url: '/gallaries',
        },
        {
            id: 6,
            title: 'About Us',
            url: '/about-us',
        },
    ];

    return (
        <>
            <AppBar position="fixed" sx={{ py: { md: 0, xs: 1 } }}>
                <SpacingContent>
                    <Toolbar
                        sx={{
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* ################### LEFT ################# */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                noWrap
                                component="div"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Link to={'/'}>
                                    <Avatar
                                        src={logo}
                                        sx={{
                                            height: 60,
                                            width: 60,
                                        }}
                                    />
                                </Link>
                            </Typography>
                        </Box>

                        {/* ################### CENTER ################# */}
                        <Box sx={{ textAlign: 'center' }}>
                            {/* <a href="tel:+255 763 101022">
                                <Typography
                                    sx={{
                                        'py': 1,
                                        'px': 2,
                                        'borderRadius': 2,
                                        'color': colors.textColor,
                                        'display': {
                                            md: 'none',
                                            xs: 'block',
                                        },
                                        'fontWeight': 'bold',
                                        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                        'transition': `.5s all ease-in-out`,
                                        ':hover': {
                                            transform: `scale(1.03)`,
                                            transition: `.5s all ease-in-out`,
                                        },
                                    }}
                                >
                                    +255 763 101022
                                </Typography>
                            </a> */}
                            <List
                                sx={{
                                    'position': 'relative',
                                    'height': 55,
                                    'bottom': 0,
                                    'right': 0,
                                    'left': 0,
                                    'p': 0,
                                    'display': { md: 'flex', xs: 'none' },
                                    'justifyContent': 'space-between',
                                    'alignItems': 'center',
                                    '& .item': {
                                        'display': 'flex',
                                        'flexDirection': 'column',
                                        // 'width': '25%',
                                        '& .btn': {
                                            display: 'flex',
                                            px: { md: 1, xs: '100%' },
                                            textAlign: 'center',
                                            alignItems: 'center',
                                        },
                                        '& .icon': {
                                            opacity: 0.8,
                                            fontSize: 20,
                                            mr: 1,
                                        },
                                        '& .title': {
                                            opacity: 0.8,
                                            fontWeight: 'bold',
                                        },
                                    },
                                }}
                            >
                                {links?.map((link) => (
                                    <NavLink
                                        to={link.url}
                                        style={{ width: '100%' }}
                                        key={link.id}
                                    >
                                        {({ isActive }) => (
                                            <ListItem className="item">
                                                <ListItemButton
                                                    className="btn"
                                                    sx={{
                                                        color: isActive
                                                            ? colors.secondary
                                                            : colors.textColor,
                                                    }}
                                                >
                                                    {link.icon}
                                                    <Typography
                                                        noWrap
                                                        className="title"
                                                        sx={{
                                                            color: isActive
                                                                ? colors.secondary
                                                                : 'auto',
                                                        }}
                                                    >
                                                        {link.title}
                                                    </Typography>
                                                </ListItemButton>
                                            </ListItem>
                                        )}
                                    </NavLink>
                                ))}
                            </List>
                        </Box>

                        {/* ################### RIGHT ################# */}
                        <TopbarRight
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}
                        >
                            <Box>
                                <a href="tel:+255 763 101 022">
                                    <Typography
                                        sx={{
                                            'py': 1,
                                            'px': 2,
                                            'borderRadius': 2,
                                            'color': colors.textColor,
                                            'display': {
                                                md: 'block',
                                                xs: 'none',
                                            },
                                            'fontWeight': 'bold',
                                            'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                            'transition': `.5s all ease-in-out`,
                                            ':hover': {
                                                transform: `scale(1.03)`,
                                                transition: `.5s all ease-in-out`,
                                            },
                                        }}
                                    >
                                        +255 763 101022
                                    </Typography>
                                </a>
                            </Box>
                            {mobileOpen ? (
                                <IconButton
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                    sx={{
                                        display: {
                                            xs: 'block',
                                            sm: 'none',
                                        },
                                        marginTop: 1.3,
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: 35 }} />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                    sx={{
                                        display: {
                                            xs: 'block',
                                            sm: 'none',
                                        },
                                        marginTop: 1.3,
                                    }}
                                >
                                    <Segment sx={{ fontSize: 35 }} />
                                </IconButton>
                            )}
                        </TopbarRight>
                    </Toolbar>
                </SpacingContent>
            </AppBar>
        </>
    );
};

export default HomeTopbar;
