import React from 'react';
import { Box } from '@mui/material';
import { colors } from '../../assets/utils/colors';

const SocialLinkWidget = ({ link }) => {
    return (
        <>
            <a href={link.url}>
                <Box
                    sx={{
                        'display': 'flex',
                        'alignItems': 'center',
                        'borderRadius': 100,
                        'height': 65,
                        'overflow': 'hidden',
                        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        'border': `3px solid ${colors.bgColor3}`,
                        'transition': `.3s all ease-in-out`,
                        'position': 'relative',
                        'justifyContent': 'center',
                        '& .title': {
                            fontSize: 12,
                            fontWeight: 'bold',
                            opacity: 0.8,
                        },
                        '& .icon-con': {
                            'height': 60,
                            'width': 60,
                            'borderRadius': 50,
                            'background': `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                            'display': 'flex',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'mr': 1,
                            'transition': `.3s all ease-in-out`,
                            'borderRight': `3px dotted ${colors.bgColor4}`,
                            'borderLeft': `3px dotted ${colors.bgColor4}`,
                            '& .icon': {
                                fontSize: 30,
                                color: link.iconColor,
                            },
                        },
                        ':hover': {
                            '& .icon-con': {
                                transition: `.3s all ease-in-out`,
                                borderRight: `3px dotted ${colors.secondary}`,
                                borderLeft: `3px dotted ${colors.secondary}`,
                            },
                        },
                    }}
                >
                    <Box className={'icon-con'}>{link.icon}</Box>
                    {/* <Typography className="title">{link.title}</Typography> */}
                </Box>
            </a>
        </>
    );
};

export default SocialLinkWidget;
