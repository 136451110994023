import React from 'react';
import SpacingContent from './SpacingContent';
import Section from './Section';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from './forms/CustomTextField';
import {
    Facebook,
    Instagram,
    LinkedIn,
    Message,
    Phone,
    PinDrop,
    Send,
    WhatsApp,
    YouTube,
} from '@mui/icons-material';
import ContactWidget from './widgets/ContactWidget';
import SocialLinkWidget from './widgets/SocialLinkWidget';
import { colors } from '../assets/utils/colors';
import contactUsBg from '../assets/media/images/logo2.png';
import CustomMultlineTextField from './forms/CustomMultlineTextField';
import CustomSubmitButton from './forms/CustomSubmitButton';

const ContactUs = () => {
    const contacts = [
        {
            id: 1,
            title: 'Phone number',
            icon: <Phone className="icon" />,
            value: '+255 763 101022',
        },
        {
            id: 2,
            title: 'Email us',
            icon: <Message className="icon" />,
            value: 'info@thev-events.co.tz',
            border: true,
        },
        {
            id: 3,
            title: 'Physical address',
            icon: <PinDrop className="icon" />,
            value: 'Jamuhuri Ilala Dar es Salaam Tanzania',
        },
    ];
    const socialLinks = [
        {
            id: 1,
            title: 'WhatsApp',
            url: '',
            iconColor: 'green',
            icon: <WhatsApp className="icon" />,
        },
        {
            id: 2,
            title: 'Facebook',
            url: '',
            iconColor: 'blue',
            icon: <Facebook className="icon" />,
        },
        {
            id: 3,
            title: 'Instagram',
            url: '',
            iconColor: 'brown',
            icon: <Instagram className="icon" />,
        },
        {
            id: 4,
            title: 'YouTube',
            url: '',
            iconColor: 'red',
            icon: <YouTube className="icon" />,
        },
        {
            id: 5,
            title: 'LinkedIn',
            url: '',
            iconColor: '#0077b6',
            icon: <LinkedIn className="icon" />,
        },
    ];
    return (
        <>
            <SpacingContent>
                <Box sx={{ mt: {md: 20, xs: 25} }}>
                    <Section>
                        <Grid
                            container
                            spacing={5}
                            sx={{
                                pr: { md: 5, xs: 0 },
                                pb: 10,
                                borderRadius: 15,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                            }}
                        >
                            <Grid item sm={5} xs={12}>
                                <Box
                                    sx={{
                                        mt: -25,
                                        backgroundImage: `url(${contactUsBg})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: '100% 400px',
                                        backgroundRepeat: 'no-repeat',
                                        borderTopLeftRadius: {md: 150, xs:50},
                                        borderBottomRightRadius: {md: 150, xs:50},
                                        position: 'relative',
                                        overflow: 'hidden',
                                        height: 450,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            right: 0,
                                            left: 0,
                                            bgcolor: `rgb(236, 246, 255, .95)`,
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        md: 25,
                                                        xs: 20,
                                                    },
                                                    fontWeight: 'bold',
                                                    pt: 4,
                                                    pb: 2,
                                                    textAlign: 'center',
                                                    color: colors.textColor,
                                                    opacity: 0.8,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Leave us{' '}
                                                <span
                                                    style={{
                                                        color: colors.secondary,
                                                    }}
                                                >
                                                    a message
                                                </span>
                                            </Typography>
                                        </Box>
                                        <Box sx={{}}>
                                            <Formik
                                                initialValues={{
                                                    full_name: '',
                                                    email: '',
                                                    subject: '',
                                                    message: '',
                                                }}
                                            >
                                                {(formik) => (
                                                    <Form>
                                                        <Grid
                                                            container
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            columnSpacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                sm={5}
                                                                xs={5.5}
                                                            >
                                                                <CustomTextField
                                                                    name="full_name"
                                                                    type={
                                                                        'text'
                                                                    }
                                                                    label={
                                                                        'Full name'
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={5}
                                                                xs={5.5}
                                                            >
                                                                <CustomTextField
                                                                    name="email"
                                                                    type={
                                                                        'email'
                                                                    }
                                                                    label={
                                                                        'Your email'
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={10}
                                                                xs={11}
                                                            >
                                                                <CustomTextField
                                                                    name="subject"
                                                                    type={
                                                                        'text'
                                                                    }
                                                                    label={
                                                                        'Subject'
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={10}
                                                                xs={11}
                                                            >
                                                                <CustomMultlineTextField
                                                                    name="message"
                                                                    type={
                                                                        'text'
                                                                    }
                                                                    label={
                                                                        'Message'
                                                                    }
                                                                    rows={4}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={6}
                                                                sx={{ mt: 2 }}
                                                            >
                                                                <CustomSubmitButton
                                                                    endIcon={
                                                                        <Send />
                                                                    }
                                                                >
                                                                    Send message
                                                                </CustomSubmitButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={7} xs={12}>
                                <Box
                                    sx={{
                                        height: 200,
                                        mt: -25,
                                        display: { md: 'flex', xs: 'none' },
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        p: 5,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 25,
                                            color: colors.secondary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Get in touch
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 35,
                                            fontWeight: 'bold',
                                            opacity: 0.8,
                                            color: colors.textColor,
                                        }}
                                    >
                                        With us
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        height: { md: 250, xs: '100%' },
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                                    }}
                                >
                                    <Box
                                        sx={{ display: 'flex', width: '100%' }}
                                    >
                                        {contacts?.map((contact) => (
                                            <ContactWidget
                                                key={contact.id}
                                                contact={contact}
                                            />
                                        ))}
                                    </Box>
                                    <Box sx={{ mt: 5 }}>
                                        <Typography
                                            sx={{
                                                pt: 2,
                                                pl: 2,
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                opacity: 0.8,
                                            }}
                                        >
                                            Our social links
                                        </Typography>
                                        <Box sx={{ display: 'flex', width: '100%' }}>
                                            <Grid
                                                container
                                                spacing={2}
                                                padding={2}
                                            >
                                                {socialLinks?.map((link) => (
                                                    <Grid
                                                        item
                                                        sm={2}
                                                        xs={4}
                                                        key={link.id}
                                                    >
                                                        <SocialLinkWidget
                                                            link={link}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Section>
                </Box>
            </SpacingContent>
        </>
    );
};

export default ContactUs;
