import React from 'react';
import { Avatar, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { DoubleArrow } from '@mui/icons-material';

const ServicedetailsWidget = ({ service }) => {
    const deviceWidth = useMediaQuery(`(min-width:768px)`);
    return (
        <>
            <Grid
                container
                spacing={{ md: 4, xs: 1 }}
                sx={{ mb: 15 }}
                justifyContent={'center'}
            >
                <Grid
                    item
                    sm={5}
                    xs={11}
                    order={service.imgOrder}
                    sx={{
                        mt: { md: 0, xs: 3 },
                    }}
                >
                    <Box
                        sx={{
                            'position': 'relative',
                            'height': '100%',
                            display: 'flex',
                            alignItems: 'center',
                            // ':before': {
                            //     content: "''",
                            //     height: { md: 250, xs: 150 },
                            //     width: { md: 150, xs: 100 },
                            //     position: 'absolute',
                            //     left: -10,
                            //     top: -10,
                            //     borderTop: `10px solid ${colors.bgColor6}`,
                            //     borderLeft: `10px solid ${colors.bgColor6}`,
                            //     borderTopLeftRadius: {
                            //         md: 70,
                            //         xs: 35,
                            //     },
                            // },
                            // ':after': {
                            //     content: "''",
                            //     height: { md: 250, xs: 150 },
                            //     width: { md: 150, xs: 100 },
                            //     position: 'absolute',
                            //     right: -10,
                            //     top: -10,
                            //     borderTop: `10px solid ${colors.bgColor6}`,
                            //     borderRight: `10px solid ${colors.bgColor6}`,
                            //     borderTopRightRadius: {
                            //         md: 80,
                            //         xs: 40,
                            //     },
                            // },
                        }}
                    >
                        <Avatar
                            src={service.image}
                            variant="square"
                            sx={{
                                width: '100%',
                                height: { md: 350, xs: 220 },
                                borderRadius: deviceWidth ? 15 : 5,
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item sm={7} order={service.contentOrder}>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            px: 4,
                        }}
                    >
                        <Box>
                            <Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            borderBottom: `5px double ${colors.secondary}`,
                                            width: 60,
                                            height: 60,
                                            borderRadius: 50,
                                            fontSize: 35,
                                            fontStyle: 'italic',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: colors.secondary,
                                        }}
                                    >
                                        {service.id}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                md: 20,
                                                xs: 18,
                                            },
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            color: colors.secondary,
                                            textAlign: {
                                                md: 'left',
                                                xs: 'center',
                                            },
                                        }}
                                    >
                                        {service.service}
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        py: 3,
                                        opacity: 0.8,
                                    }}
                                >
                                    {service.description}
                                </Typography>
                            </Box>
                            <Box>
                                {service?.subServices.map((service) => (
                                    <Box key={service.id}>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <DoubleArrow
                                                color="secondary"
                                                sx={{
                                                    mr: 1,
                                                    fontSize: 16,
                                                }}
                                            />
                                            {service.service}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ServicedetailsWidget;
