import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';

const ContactWidget = ({ contact, border }) => {
    return (
        <>
            <Box
                sx={{
                    'background': `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                    'width': '100%',
                    'display': 'flex',
                    'flexDirection': 'column',
                    'alignItems': 'center',
                    'justifyContent': 'center',
                    'borderRight':
                        contact.border && `2px solid ${colors.bgColor6}`,
                    'borderLeft':
                        contact.border && `2px solid ${colors.bgColor6}`,
                    'pb': 2,
                    '& .title': {
                        fontSize: 14,
                        fontWeight: 'bold',
                        opacity: 0.8,
                    },
                    '& .value': {
                        fontSize: 12,
                        textAlign: 'center',
                        opacity: 0.8,
                    },
                    '& .icon': {
                        fontSize: 35,
                        opacity: 0.8,
                        color: colors.secondary,
                    },
                }}
            >
                {contact.icon}
                <Typography className="title">{contact.title}</Typography>
                <Typography className="value">{contact.value}</Typography>
            </Box>
        </>
    );
};

export default ContactWidget;
