import React, { useState } from "react";
import SpacingContent from "../components/SpacingContent";
import Section from "../components/Section";
import {
    Box,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import PageLayout from "./layouts/PageLayout";
import GallaryView from "../components/GallaryView";
import { useDispatch, useSelector } from "react-redux";
import { gallariesSelector } from "../state/features/selectors";
import { ReadMore, SlideshowRounded } from "@mui/icons-material";
import { colors } from "../assets/utils/colors";
import SlideShowView from "../components/SlideShowView";
import { shuffle } from "lodash";
import { setGallaries } from "../state/features/gallaries/gallariesSlice";
import useRTK from "../hooks/useRTK";
import { useGetPublicGallariesQuery } from "../api/gallaryAPI/gallaryAPI";

const Gallaries = () => {
    // ############### Comp State ####################
    const [toggler, setToggler] = React.useState(false);
    const [slideShowOpen, setSlideshowOpen] = useState(false);
    const [slideShow, setSlideshow] = useState([]);
    const [gallaryView, setGallaryView] = React.useState(null);
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### CONST ####################
    const deviceWidth = useMediaQuery("(min-width:600px)");

    // ############### Redux state ##############
    const { gallaries } = useSelector(gallariesSelector);

    // ############## RTK #################
    const { isSuccess, data: gallaryData } = useGetPublicGallariesQuery();

    // ############## useEffect ##################
    const rtkGallaryAction = (data) => {
        dispatch(setGallaries(data));
    };
    RTK.useRTKFetch(isSuccess, gallaryData, rtkGallaryAction);

    // ############## FUNC ######################
    const handleGallaryView = (gallary) => {
        setToggler(!toggler);
        setGallaryView(gallary);
    };
    const handleSlideShow = () => {
        setSlideshowOpen(!slideShowOpen);
        let slideShow = [];
        gallaries.map((gallary) => {
            slideShow.push(gallary);
            return true;
        });
        setSlideshow(slideShow);
    };
    return (
        <>
            <GallaryView toggler={toggler} gallary={gallaryView} />
            <SlideShowView toggler={slideShowOpen} gallary={slideShow} />
            <PageLayout
                head={"Events gallaries"}
                subHead={"Overlook what we already archived"}
            >
                <SpacingContent maxPadding={2}>
                    <Section>
                        <Box
                            sx={{
                                position: "sticky",
                                top: { md: 64, xs: 77 },
                                left: 0,
                                right: 0,
                                display: "flex",
                                justifyContent: "center",
                                zIndex: 10,
                                mb: 5,
                            }}
                        >
                            <IconButton
                                size="small"
                                sx={{
                                    border: `5px solid ${colors.bgColor6}`,
                                    bgcolor: colors.bgColor3,
                                    borderRadius: `100px !important`,
                                    transition: `.3s all ease-in-out`,
                                    p: 1,
                                    "& .play": {
                                        color: colors.secondary,
                                        transition: `.3s all ease-in-out`,
                                    },
                                    ":hover": {
                                        bgcolor: colors.bgColor3,
                                        transition: `.3s all ease-in-out`,
                                        "& .play": {
                                            fontSize: { md: 55, xs: 55 },
                                            // transform: `rotate(360deg)`,
                                            transition: `.3s all ease-in-out`,
                                        },
                                    },
                                }}
                                onClick={() => handleSlideShow()}
                            >
                                <SlideshowRounded
                                    className="play"
                                    sx={{ fontSize: 50 }}
                                />
                            </IconButton>
                        </Box>
                        <ImageList
                            gap={deviceWidth ? 15 : 15}
                            cols={deviceWidth ? 4 : 2}
                        >
                            {shuffle(gallaries)?.map((gallary, index) => (
                                <ImageListItem
                                    key={index}
                                    sx={{
                                        borderRadius: { md: 2, xs: 2 },
                                        overflow: "hidden",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleGallaryView(gallary)}
                                >
                                    <img
                                        src={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                                        srcSet={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                                        alt={gallary.event}
                                        loading="lazy"
                                        style={{
                                            height: deviceWidth ? 250 : 120,
                                        }}
                                    />
                                    <ImageListItemBar
                                        title={
                                            <Typography
                                                noWrap
                                                sx={{ fontSize: 13 }}
                                            >
                                                {gallary.event}
                                            </Typography>
                                        }
                                        subtitle={`@ ${gallary.year}`}
                                        actionIcon={
                                            <IconButton
                                                sx={{
                                                    color: "rgba(255, 255, 255, 0.54)",
                                                }}
                                                aria-label={`info about ${gallary.event}`}
                                                onClick={() =>
                                                    handleGallaryView(gallary)
                                                }
                                            >
                                                <ReadMore color="secondary" />
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default Gallaries;
