import React from 'react';
import { Grid } from '@mui/material';
import Section from './Section';
import SpacingContent from './SpacingContent';
import { SwiperSlide } from 'swiper/react';
import EventTypeWidget from './widgets/EventTypeWidget';
import CustomSwiper from './swipers/CustomSwiper';
import { useSelector } from 'react-redux';
import { eventTypesSelector } from '../state/features/selectors';

const EventTypes = () => {
    const { eventTypes } = useSelector(eventTypesSelector);

    return (

        <>
            <SpacingContent maxPadding={3}>
                <Section head={'Event types'} subHead={'We can host'}>
                    <Grid container>
                        <CustomSwiper conHeight={{ md: 300, xs: 400 }}>
                            {eventTypes?.map((event, index) => (
                                <SwiperSlide key={index}>
                                    <EventTypeWidget event={event} />
                                </SwiperSlide>
                            ))}
                        </CustomSwiper>
                    </Grid>
                </Section>
            </SpacingContent>
        </>
    );
};

export default EventTypes;
