import relation from '../../assets/media/images/relation.jpg';
import destination from '../../assets/media/images/destination.JPG';
import foundrize from '../../assets/media/images/foundrize.jpg';
import ground from '../../assets/media/images/ground.jpeg';
import team from '../../assets/media/images/team.jpeg';
import capacity from '../../assets/media/images/capacity.jpeg';
import award from '../../assets/media/images/events.jpeg';
import auction from '../../assets/media/images/auction.jpeg';
import building from '../../assets/media/images/building2.JPG';

export const eventTypes = [
    {
        id: 1,
        event: 'Annual Conferences',
        year: '2023',
        image: destination,
    },
    {
        id: 2,
        event: 'Symposiums & Destination Meetings',
        year: '2022/2023',
        image: ground,
    },
    {
        id: 3,
        event: 'Educational Seminars',
        year: '2022',
        image: team,
    },
    {
        id: 4,
        event: 'Fundraising',
        year: '2022',
        image: foundrize,
    },
    {
        id: 5,
        event: 'Capacity building',
        year: '2022',
        image: capacity,
    },
    {
        id: 6,
        event: 'Long Service Awards & Recognition events',
        year: '2022',
        image: award,
    },
    {
        id: 7,
        event: 'Silent & Live auctions',
        year: '2022',
        image: auction,
    },
    {
        id: 8,
        event: 'Ground breakings & building openings',
        year: '2022',
        image: building,
    },
    {
        id: 9,
        event: 'Media & Public Relation Events',
        year: '2022',
        image: relation,
    },
];
