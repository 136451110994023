import React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { colors } from '../assets/utils/colors';
import SpacingContent from '../components/SpacingContent';
import logo from '../assets/media/images/logo1.png';
import ceo from '../assets/media/images/ceo2.jpg';
import VissionAndMission2 from '../components/VissionAndMission2';

const AboutUs = () => {
    return (
        <>
            <SpacingContent>
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, #000, #000)`,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    p: 2,
                                }}
                            >
                                <Avatar
                                    src={logo}
                                    variant="square"
                                    sx={{
                                        height: 300,
                                        width: 150,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={8}
                        xs={12}
                        sx={{
                            position: 'relative',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 500,
                            }}
                        >
                            <Avatar
                                src={logo}
                                variant="square"
                                sx={{ height: 300, width: 250, opacity: 0.05 }}
                            />
                        </Box>
                        <SpacingContent>
                            <Box
                                sx={{
                                    height: { md: 300, xs: '50vh' },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    backgroundImage: {
                                        md: 'none',
                                        xs: `url(${logo})`,
                                    },
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundAttachment: 'fixed',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 0,
                                        left: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        bgcolor: {
                                            md: 'transparent',
                                            xs: `rgb(236, 246, 255, .9)`,
                                        },
                                        padding: { md: 0, xs: 4 },
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 30,
                                                fontWeight: 'bold',
                                                color: colors.textColor,
                                                textTransform: 'uppercase',
                                                opacity: 0.8,
                                            }}
                                        >
                                            The V-Event Management team
                                        </Typography>
                                    </Box>
                                    <Box sx={{ my: 2 }}>
                                        <Typography>
                                            Comprised of a group of highly{' '}
                                            <strong
                                                style={{
                                                    color: colors.secondary,
                                                }}
                                            >
                                                motivated, dedicated
                                                professionals,
                                            </strong>{' '}
                                            who bring together a wide variety of
                                            specialized skills and knowledge in
                                            order to meet the changing needs of
                                            our clients.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </SpacingContent>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={12}>
                        <VissionAndMission2 />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        sm={8}
                        xs={12}
                        order={{ md: 1, xs: 2 }}
                        sx={{
                            position: 'relative',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                        }}
                    >
                        <SpacingContent>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    backgroundImage: {
                                        md: 'none',
                                        xs: `url(${logo})`,
                                    },
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundAttachment: 'fixed',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        bgcolor: {
                                            md: 'transparent',
                                            xs: `rgb(236, 246, 255, .9)`,
                                        },
                                        padding: { md: 0, xs: 4 },
                                    }}
                                >
                                    <Box sx={{ mt: 5 }}>
                                        <Typography
                                            sx={{
                                                fontSize: 30,
                                                fontWeight: 'bold',
                                                color: colors.textColor,
                                                textTransform: 'uppercase',
                                                opacity: 0.8,
                                            }}
                                        >
                                            The V-Event CO-founder
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 20,
                                                fontWeight: 'bold',
                                                color: colors.textColor,
                                                textTransform: 'uppercase',
                                                opacity: 0.8,
                                            }}
                                        >
                                            Word from CEO.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ py: 5 }}>
                                        <Typography
                                            sx={{ fontSize: 15, mb: 2 }}
                                        >
                                            <strong>Hello!</strong> and welcome
                                            to
                                            <strong
                                                style={{
                                                    color: colors.secondary,
                                                }}
                                            >
                                                {' '}
                                                The V-Events!{' '}
                                            </strong>
                                            My name is Ms. Mwita, and I am
                                            thrilled that you&#39;re here! I'm
                                            in love with creating unique
                                            experiences and connecting people.
                                            This website offers customised
                                            services and materials.
                                        </Typography>
                                        <Typography
                                            sx={{ fontSize: 15, mb: 2 }}
                                        >
                                            We, at the V Events, have extensive
                                            experience in Event Planning and
                                            Consultancy, Capacity Building,
                                            Conference Coordination,
                                            Fundraising, Marketing Strategies,
                                            and Training facilitation.
                                        </Typography>
                                        <Typography
                                            sx={{ fontSize: 15, mb: 2 }}
                                        >
                                            We assist companies and people
                                            improve capability through
                                            customised trainings. Creating and
                                            leading workshops to help clients
                                            succeed by understanding their
                                            requirements and goals. We make
                                            Business Conferences, Fundraising,
                                            Community Events, and Capacity
                                            Building workshops memorable.
                                        </Typography>
                                        <Typography
                                            sx={{ fontSize: 15, mb: 2 }}
                                        >
                                            We are detail-oriented,
                                            Professionals, and dedicated to
                                            surpassing client expectations. With
                                            us, your event or project is in good
                                            hands.
                                        </Typography>
                                        <Typography
                                            sx={{ fontSize: 15, mb: 2 }}
                                        >
                                            Thank you for visiting The V Events
                                            Website, and we encourage you to
                                            explore the various sections to
                                            learn more about how we can help you
                                            achieve your goals. If you have any
                                            questions or would like to discuss
                                            your specific requirements, please
                                            feel free to contact us.
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 20,
                                                mt: 1,
                                                fontWeight: 'bold',
                                                opacity: 0.8,
                                            }}
                                        >
                                            Asante sana,
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                                color: colors.secondary,
                                            }}
                                        >
                                            Ms. Mwita.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </SpacingContent>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        order={{ md: 2, xs: 1 }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                        }}
                    >
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, #000, #000)`,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    p: 2,
                                    position: 'relative',
                                }}
                            >
                                <Avatar
                                    src={ceo}
                                    variant="square"
                                    sx={{
                                        height: 300,
                                        width: '100%',
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        height: 60,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                                        borderLeft: `16px solid #000`,
                                        borderRight: `16px solid #000`,
                                        borderBottom: `16px solid #000`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Ms.{' '}
                                        <strong
                                            style={{ color: colors.secondary }}
                                        >
                                            Mwita
                                        </strong>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </SpacingContent>
        </>
    );
};

export default AboutUs;
