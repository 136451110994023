import { Box } from "@mui/material";
import React from "react";
import { colors } from "../assets/utils/colors";

function SectionBgGradient() {
    return (
        <>
            <Box
                sx={{
                    // height: 300,
                    // width: '100%',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    ":before": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderTopRightRadius: 550,
                        borderBottomRightRadius: 550,
                        borderBottomLeftRadius: 150,
                        transform: `rotate(45deg)`,
                        zIndex: 1,
                        background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                        opacity: 0.2,
                    },
                    ":after": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderBottomLeftRadius: 550,
                        borderTopLeftRadius: 550,
                        borderBottomRightRadius: 150,
                        transform: `rotate(-45deg)`,
                        zIndex: 1,
                        background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                        opacity: 0.2,
                    },
                }}
            ></Box>
        </>
    );
}

export default SectionBgGradient;
