import React from "react";
import Banner from "../components/Banner";
import OurServices from "../components/OurServices";
import VissionAndMission from "../components/VissionAndMission";
import OurClients from "../components/OurClients";
import EventTypes from "../components/EventTypes";
import ContactUs from "../components/ContactUs";
import EventGallaries from "../components/EventGallaries";
import OurPartiners from "../components/OurPartiners";
import UpcomingEventBanner from "./events/components/UpcomingEventBanner";
import EventsPortifolio from "../components/home/EventsPortifolio";

const Homepage = () => {
    return (
        <>
            {/* ########### Banner ############# */}
            <Banner />

            {/* ########### Vission and Mission ########## */}
            <VissionAndMission />

            {/* ########### Our service ########## */}
            {/* <UpcomingEventBanner /> */}

            {/* ########### Our service ########## */}
            <OurServices />

            {/* ########### Our Clients ########## */}
            <OurClients />

            {/* ########### Event types ########## */}
            <EventTypes />

            {/* ########### Events Portifolio ########## */}
            {/* <EventsPortifolio /> */}

            {/* ########### Event Gallaries ########## */}
            <EventGallaries />

            {/* ########### Contact us ########## */}
            <ContactUs />

            {/* ########### Our Partiners ########## */}
            <OurPartiners />
        </>
    );
};

export default Homepage;
