import React from 'react';
import { Grid } from '@mui/material';
import Section from '../components/Section';
import SpacingContent from '../components/SpacingContent';
import PageLayout from './layouts/PageLayout';
import EventTypeWidget from '../components/widgets/EventTypeWidget';
import { eventTypesSelector } from '../state/features/selectors';
import { useSelector } from 'react-redux';

const EventTypes = () => {
    const { eventTypes } = useSelector(eventTypesSelector);

    return (
        <>
            <PageLayout
                head={'Event types'}
                subHead={'We can host'}
            >
                <SpacingContent>
                    <Section>
                        <Grid
                            container
                            spacing={{ md: 4, xs: 3 }}
                            padding={{ xs: 2 }}
                        >
                            {eventTypes?.map((event, index) => (
                                <Grid item md={3} xs={12} key={index}>
                                    <EventTypeWidget event={event} />
                                </Grid>
                            ))}
                        </Grid>
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default EventTypes;
