import { Avatar, Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import SpacingContent from './SpacingContent';
import logo from '../assets/media/images/logo2.png';
import { colors } from '../assets/utils/colors';
import { WhatsApp } from '@mui/icons-material';

const Footer = () => {
    const date = new Date();
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                }}
            >
                <SpacingContent>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                p: 2,
                            }}
                        >
                            <Avatar
                                src={logo}
                                sx={{
                                    height: 100,
                                    width: 100,
                                }}
                            />
                            <Typography
                                sx={{ fontSize: 14, fontWeight: 'bold' }}
                            >
                                All rights reserved | v-events &copy;{' '}
                                {date.getFullYear()}
                            </Typography>
                        </Box>
                    </Box>
                </SpacingContent>
                <Box
                    sx={{
                        position: 'fixed',
                        borderRadius: 50,
                        overflow: 'hidden',
                        right: { md: 25, xs: 10 },
                        bottom: { md: 25, xs: 10 },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 999,
                        border: `5px solid ${colors.bgColor3}`,
                    }}
                >
                    <a href="https://wa.me/+255763101022">
                        <IconButton
                            sx={{
                                height: { md: 80, xs: 60 },
                                width: { md: 80, xs: 60 },
                                boxShadow: `1px 1px 10px 0px ${colors.secondary}`,
                                background: `linear-gradient(180deg, rgb(21, 255, 21), rgb(0, 191, 0))`,
                            }}
                        >
                            <WhatsApp
                                sx={{
                                    fontSize: { md: 55, xs: 40 },
                                    color: colors.bgColor1,
                                }}
                            />
                        </IconButton>
                    </a>
                </Box>
            </Box>
        </>
    );
};

export default Footer;
