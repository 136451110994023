import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { colors } from '../assets/utils/colors';
import useColor from '../hooks/useColor';
import { Box, Grid, List } from '@mui/material';
import CustomListItem from './CustomListItem';
import {
    Facebook,
    Instagram,
    LinkedIn,
    WhatsApp,
    Window,
    YouTube,
} from '@mui/icons-material';
import SocialLinkWidget from './widgets/SocialLinkWidget';

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    'width': drawerWidth,
    'flexShrink': 0,
    'whiteSpace': 'nowrap',
    'boxSizing': 'border-box',
    '& .MuiPaper-root': {
        backgroundColor: colors.primary,
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

// ################# DrawerContent ################
const DrawerContent = styled(Box)(({ theme }) => ({
    'bgcolor': colors.bgColor3,
    'height': '100vh',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingTop': 20,
    'paddingBottom': 20,
    'paddingLeft': 15,
    'paddingRight': 15,
    'transition': '.3s all ease-in-out',
    '& .list-title': {
        fontWeight: 'bold',
        fontSize: 12,
        padding: 5,
        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    },
    '& .list-icon': {
        fontSize: 18,
        color: colors.secondary,
    },
}));

//! #################### MAIN FUNC ######################
const HomeSidebar = ({ handleDrawerToggle, mobileOpen }) => {
    // ############ CONST ###################
    const colors = useColor();
    const [open] = React.useState(false);

    const AppBarHeight = styled('div')(({ theme }) => ({
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const { window } = 'props';
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ################# DRAWER CONTENT ################
    const navLinks = [
        {
            id: 1,
            text: 'V-Events',
            icon: <Window className="list-icon" />,
            url: '/',
        },
        {
            id: 2,
            text: 'Our services',
            icon: <Window className="list-icon" />,
            url: '/services',
        },
        {
            id: 3,
            text: 'Event types',
            icon: <Window className="list-icon" />,
            url: '/event-types',
        },
        {
            id: 4,
            text: 'Our clients',
            icon: <Window className="list-icon" />,
            url: '/clients',
        },
        {
            id: 5,
            text: 'Events gallaries',
            icon: <Window className="list-icon" />,
            url: '/gallaries',
        },
        {
            id: 6,
            text: 'About Us',
            icon: <Window className="list-icon" />,
            url: '/about-us',
        },
    ];
    const socialLinks = [
        {
            id: 1,
            title: 'WhatsApp',
            url: '',
            iconColor: 'green',
            icon: <WhatsApp className="icon" />,
        },
        {
            id: 2,
            title: 'Facebook',
            url: '',
            iconColor: 'blue',
            icon: <Facebook className="icon" />,
        },
        {
            id: 3,
            title: 'Instagram',
            url: '',
            iconColor: 'brown',
            icon: <Instagram className="icon" />,
        },
        {
            id: 4,
            title: 'YouTube',
            url: '',
            iconColor: 'red',
            icon: <YouTube className="icon" />,
        },
        {
            id: 5,
            title: 'LinkedIn',
            url: '',
            iconColor: '#0077b6',
            icon: <LinkedIn className="icon" />,
        },
    ];
    const drawer = (
        <React.Fragment>
            <DrawerContent>
                <Box sx={{ mb: 5 }}>
                    <Box>
                        <List sx={{ p: 0 }}>
                            {navLinks?.map((item) => (
                                <CustomListItem
                                    key={item.id}
                                    text={item.text}
                                    icon={item.icon}
                                    url={item.url}
                                />
                            ))}
                        </List>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Grid container spacing={3} padding={2}>
                        {socialLinks?.map((link) => (
                            <Grid item sm={3} xs={4} key={link.id}>
                                <SocialLinkWidget link={link} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </DrawerContent>
        </React.Fragment>
    );
    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    'display': { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: '80%', // Change the width of drawer in mobile
                        backgroundColor: colors.primary,
                    },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ##################### desktop ################ */}
            <DrawerDesktop
                variant="temporary"
                open={open}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
            >
                <AppBarHeight />
                <Divider />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default HomeSidebar;
