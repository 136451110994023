import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { colors } from '../assets/utils/colors';

const CustomListItem = ({ desktopOpen, text, icon, url }) => {
    return (
        <React.Fragment>
            <NavLink to={url}>
                {({ isActive }) => (
                    <ListItem disablePadding>
                        <ListItemButton
                            sx={{
                                'borderRight': isActive
                                    ? `4px solid ${colors.primary}`
                                    : undefined,
                                'height': 38,
                                'justifyContent': desktopOpen
                                    ? 'initial'
                                    : 'center',
                                'px': 2.5,
                                // 'borderBottom': `1px solid ${colors.bgColor1}`,
                                '&:hover': {
                                    bgcolor: colors.bgColor2,
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: 2,
                                    justifyContent: 'center',
                                    opacity: 0.6,
                                }}
                            >
                                {icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={text}
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: colors.textColor,
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </NavLink>
        </React.Fragment>
    );
};

export default CustomListItem;
