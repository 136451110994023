import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../assets/utils/colors';
import { ArrowRight } from '@mui/icons-material';

const VissionAndMission = () => {
    const coreValues = [
        {
            id: 1,
            value: 'Teamwork',
            desc: 'We achieve more though working together',
        },
        {
            id: 2,
            value: 'Integrity',
            desc: 'we build trust through responsible actions',
        },
        {
            id: 3,
            value: 'Confidence',
            desc: '',
        },
        {
            id: 4,
            value: 'Innovation',
            desc: 'We believe in creativity and talents',
        },
    ];
    return (
        <>
            <Grid container>
                <Grid item sm={4} xs={6}>
                    <Box
                        sx={{
                            height: { md: 220, xs: 150 },
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            display: 'flex',
                            // justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 2,
                            pt: 8
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { md: 30, xs: 18 },
                                fontWeight: 'bold',
                                color: colors.secondary,
                            }}
                        >
                            Our vission
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 20,
                                    xs: 14,
                                    textAlign: 'center',
                                    opacity: 0.8,
                                },
                            }}
                        >
                            To create a better event experience.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <Box
                        sx={{
                            height: { md: 220, xs: 150 },
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            display: 'flex',
                            // justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 2,
                            pt: 8
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { md: 30, xs: 18 },
                                fontWeight: 'bold',
                                color: colors.secondary,
                            }}
                        >
                            Our Mission
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 20,
                                    xs: 14,
                                    textAlign: 'center',
                                    opacity: 0.8,
                                },
                            }}
                        >
                            &nbsp;&nbsp;To make it easy and soft. &nbsp;&nbsp;
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <Box
                        sx={{
                            height: { md: 220, xs: 150 },
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            display: 'flex',
                            // justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            pt: {md: 8, xs: 4}
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { md: 30, xs: 18 },
                                fontWeight: 'bold',
                                color: colors.secondary,
                            }}
                        >
                            Core values
                        </Typography>
                        <Box sx={{ textAlign: 'left' }}>
                            <Grid container padding={{ md: 0, xs: 2 }}>
                                {coreValues?.map((value, index) => (
                                    <Grid item sm={4} key={index}>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                display: 'flex',
                                                alignItems: 'center',
                                                opacity: 0.8,
                                            }}
                                        >
                                            <ArrowRight sx={{ mr: 1 }} />
                                            {value.value}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default VissionAndMission;
