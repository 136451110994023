import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../baseUrl/baseURL';

export const clientAPI = createApi({
    reducerPath: 'clientAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        getPublicClients: builder.query({
            query: ``,
        }),
    }),
});

export const { useGetPublicClientsQuery } = clientAPI;
