import { createSlice } from '@reduxjs/toolkit';
import { eventTypes } from '../../dummyStates/eventTypes';

// => initialState
const initialState = {
    eventTypes: eventTypes,
};
export const eventTypesSlice = createSlice({
    name: 'eventTypesSlice',
    initialState,
});

export default eventTypesSlice.reducer;
