import React from 'react';
import { Grid } from '@mui/material';
import SpacingContent from '../components/SpacingContent';
import ClientWidget from '../components/widgets/ClientWidget';
import Section from '../components/Section';
import PageLayout from './layouts/PageLayout';
import { useSelector } from 'react-redux';
import { clientsSelector } from '../state/features/selectors';

const Clients = () => {
    const { clients } = useSelector(clientsSelector);

    return (
        <>
            <PageLayout head={'WE ARE TRUSTED'} subHead={'Pleasure to work with'}>
                <SpacingContent>
                    <Section>
                        <Grid
                            container
                            spacing={2}
                            sx={{ px: { md: 0, xs: 2 } }}
                        >
                            {clients?.map((client, index) => (
                                <Grid item sm={3} xs={12} key={index}>
                                    <ClientWidget client={client} />
                                </Grid>
                            ))}
                        </Grid>
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default Clients;
