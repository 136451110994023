import wacamed from '../../assets/media/images/partiners/wacamed.png';
import ardean from '../../assets/media/images/partiners/ardean.png';
import vevents from '../../assets/media/images/partiners/vevents.png';
import iale from '../../assets/media/images/partiners/iale.png';
import signage from '../../assets/media/images/partiners/signage.png';

export const partiners = [
    {
        id: 1,
        logo: signage,
        name: 'Civility travel',
        url: 'http://www.civilitytravel.com',
    },
    {
        id: 2,
        logo: vevents,
        name: 'V-Events',
        url: '/',
    },
    {
        id: 3,
        logo: ardean,
        name: 'Ardean',
        url: 'https://www.ardeanattorneys.co.tz',
        width: 200,
        height: 120,
    },
    {
        id: 4,
        logo: wacamed,
        name: 'Wacamed',
        url: 'https://wacamed.com',
    },
    {
        id: 5,
        logo: iale,
        name: 'Iale',
        url: 'https://www.landscape-ecology.org',
        width: 200,
        height: 120,
    },
];
