import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import SpacingContent from "../../components/SpacingContent";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import award from "../../assets/media/images/award/award_1.jpeg";
import { colors } from "../../assets/utils/colors";
import SectionBgGradient from "../../components/SectionBgGradient";
import EventCountDown from "../../components/EventCountDown";
import Section from "../../components/Section";
import logo1 from "../../assets/media/images/award/logo1.jpg";
import logo2 from "../../assets/media/images/award/logo2.jpg";
import logo3 from "../../assets/media/images/award/logo3.jpg";
import logo4 from "../../assets/media/images/award/logo4.jpg";
import logo5 from "../../assets/media/images/award/logo5.jpg";
import logo6 from "../../assets/media/images/award/logo6.jpg";
import logo7 from "../../assets/media/images/award/logo7.jpg";
import logo8 from "../../assets/media/images/award/logo8.jpg";
import logo9 from "../../assets/media/images/award/logo9.jpg";
import logo10 from "../../assets/media/images/award/logo10.jpg";

const UpcomingEventsDetailsPage = () => {
    const logos = [
        {
            id: 1,
            img: logo1,
        },
        {
            id: 2,
            img: logo2,
        },
        {
            id: 3,
            img: logo3,
        },
        {
            id: 4,
            img: logo4,
        },
        {
            id: 5,
            img: logo5,
        },
        {
            id: 6,
            img: logo6,
        },
        {
            id: 7,
            img: logo7,
        },
        {
            id: 8,
            img: logo8,
        },
        {
            id: 9,
            img: logo9,
        },
        {
            id: 10,
            img: logo10,
        },
    ];

    return (
        <>
            <Box
                sx={{
                    position: "relative",
                    // border: `10px double ${colors.secondary}`,
                    borderBottomLeftRadius: 50,
                    borderBottomRightRadius: 50,
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        height: { md: "85vh", sm: "85vh", xs: "100%" },
                        display: "flex",
                        justifyContent: "cente",
                        alignItems: "center",
                    }}
                >
                    <Grid container>
                        <Grid item sm={6} xs={12}>
                            {/* <Section> */}
                            <Box
                                sx={{
                                    height: {
                                        md: "85vh",
                                        sm: "85vh",
                                        xs: "100%",
                                    },
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    px: { md: 8, sm: 8, xs: 2 },
                                    pt: { md: 2, sm: 2, xs: 6 },
                                    position: "relative",
                                }}
                            >
                                <SectionBgGradient />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                md: 50,
                                                sm: 50,
                                                xs: 30,
                                            },
                                            fontWeight: "bold",
                                            fontStyle: "italic",
                                            textTransform: "uppercase",
                                            background: `linear-gradient(rgba(213, 138, 0, 1), rgba(0, 0, 0, .5))`,
                                            textFillColor: "transparent",
                                            backgroundClip: "text",
                                        }}
                                        className=""
                                    >
                                        <Typography
                                            component={"span"}
                                            sx={{
                                                textTransform: "lowercase",
                                                fontSize: 55,
                                            }}
                                        >
                                            I
                                        </Typography>
                                        change Nations
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                md: 50,
                                                sm: 50,
                                                xs: 30,
                                            },
                                            fontWeight: "bold",
                                            lineHeight: 1.2,
                                            mt: -1,
                                            mb: 1,
                                            fontStyle: "italic",
                                            textTransform: "uppercase",
                                            background: `linear-gradient(rgba(213, 138, 0, 1), rgba(0, 0, 0, .5))`,
                                            textFillColor: "transparent",
                                            backgroundClip: "text",
                                        }}
                                        className=""
                                    >
                                        International
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                md: 50,
                                                sm: 50,
                                                xs: 30,
                                            },
                                            fontWeight: "bold",
                                            lineHeight: 1.2,
                                            fontStyle: "italic",
                                            textTransform: "uppercase",
                                            background: `linear-gradient(rgba(213, 138, 0, 1), rgba(0, 0, 0, .5))`,
                                            textFillColor: "transparent",
                                            backgroundClip: "text",
                                            mb: { md: 15, xs: 5 },
                                        }}
                                        className=""
                                    >
                                        Awards
                                    </Typography>
                                </Box>
                                {/* <Box
                                    sx={{ display: "flex", zIndex: 50 }}
                                    gap={2}
                                >
                                    <a
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSdgCcRHsYpmuQgaHCaiFvvQ49VjFDPNUIy138rqLXNJCpWoWQ/viewform?usp=sf_link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                borderRadius: `50px !important`,
                                                pr: 10,
                                                mt: 5,
                                                mb: { md: 15, sm: 15, xs: 5 },
                                                pl: 5,
                                                py: 1.5,
                                                boxShadow: `0px 0px 5px ${colors.secondary}`,
                                                border: `1px double ${colors.secondary}`,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                vote now!
                                            </Typography>
                                            <ArrowCircleRightOutlined
                                                sx={{
                                                    position: "absolute",
                                                    right: 15,
                                                    fontSize: 35,
                                                    color: colors.secondary,
                                                }}
                                            />
                                        </Button>
                                    </a>
                                </Box> */}
                                <Box
                                    sx={{
                                        position: {
                                            md: "absolute",
                                            sm: "absolute",
                                            xs: "relative",
                                        },
                                        bottom: 0,
                                        left: { md: 50, sm: 50, xs: 0 },
                                        right: { md: 50, sm: 50, xs: 0 },
                                        mt: { md: 25, sm: 25, xs: 5 },
                                        mb: 5,
                                        borderRadius: 5,
                                        pb: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            mb: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                p: 1,
                                                textAlign: "center",
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                color: "red",
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                                            }}
                                        >
                                            The remaining time until the event:
                                        </Typography>
                                    </Box>
                                    <EventCountDown />
                                </Box>
                            </Box>
                            {/* </Section> */}
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    height: {
                                        md: "85vh",
                                        sm: "85vh",
                                        xs: "50vh",
                                    },
                                    width: `100%`,
                                    borderTopLeftRadius: 500,
                                    backgroundImage: `url(${award})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: `100% 100%`,
                                    position: "relative",
                                    borderLeft: `20px double ${colors.secondary}`,
                                }}
                            >
                                {" "}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        top: 0,
                                        overflow: "hidden",
                                    }}
                                >
                                    <marquee
                                        behavior="scroll"
                                        direction="up"
                                        scrollamount="5"
                                        style={{
                                            height: "100%",
                                            width: 100,
                                            textAlign: "center",
                                            position: "relative",
                                            borderTopLeftRadius: 10,
                                        }}
                                    >
                                        <center>
                                            {logos.map((logo, index) => (
                                                <Avatar
                                                    key={index}
                                                    src={logo.img}
                                                    sx={{
                                                        height: 80,
                                                        width: 80,
                                                        mb: 4,
                                                    }}
                                                />
                                            ))}
                                        </center>
                                    </marquee>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <SpacingContent>
                <Grid container spacing={{ md: 5, sm: 5, xs: 2 }}>
                    <Grid item sm={6} xs={12}>
                        <Box
                            sx={{
                                p: 2,
                                height: "100%",
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                borderBottom: `5px solid ${colors.secondary}`,
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 20, fontWeight: "bold" }}
                            >
                                Event date
                            </Typography>
                            <Typography sx={{ fontSize: 15, mt: 1 }}>
                                27 July 2024
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box
                            sx={{
                                p: 2,
                                height: "100%",
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                borderBottom: `5px solid ${colors.secondary}`,
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 20, fontWeight: "bold" }}
                            >
                                Venue
                            </Typography>
                            <Typography sx={{ fontSize: 14 }}>
                                Selous Plenary Hall at the Julius Nyerere
                                International Convention Centre (JNICC) Dar es
                                Salaam
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Section head={"Ichange Nations International Awards"}>
                    <Grid container sx={{ mt: 5 }} justifyContent={"center"}>
                        <Grid item sm={12} xs={11}>
                            <Typography sx={{ fontSize: 14, mb: 2 }}>
                                IChange Nations (ICN) in taasisi ya kimataifa
                                iliyo na makao yake USA huku ikipatikana pia
                                katika nchi zaidi ya 150 ulimwenguni. ICN
                                inajihusisha na kuwatambua watu walio/wanaofanya
                                mambo mema katika jamii zao katika nyanja
                                mbalimbali duniani kote na kuwatunuku tuzo za
                                heshima kwa kuutambua mchango wao. ICN ina
                                uhusiano na taasisi nyingine nyingi za
                                kimataifa.
                                <strong
                                    style={{
                                        color: colors.secondary,
                                    }}
                                >
                                    Taasisi hii ilianzishwa na Dr. Clyde Rivers
                                    ambaye pia ni mwakilishi katika jumuiya za
                                    Umoja wa Mataifa (UN).
                                </strong>
                                Soma zaidi kuhusu Dr. Rivers has been honored
                                internationally .
                            </Typography>
                            <Typography sx={{ fontSize: 14, mb: 2 }}>
                                Barani Afrika, taasisi hii imewatuza viongozi
                                mbalimbali wakiwemo maraisi, wake wa maraisi,
                                watu mashuhuri, viongozi, wakuu wa vyombo vya
                                usalama, watu wenye ushawishi, nk.
                            </Typography>
                            <Typography sx={{ fontSize: 14, mb: 2 }}>
                                Kwa mara ya kwanza hapa Tanzania, tuzo hizi
                                zitatolewa mwezi Julai 2024, jijini Dar es
                                Salaam, na Dr Ruben West (African Special
                                Envoy), kwa watanzania watakao pendekezwa.
                            </Typography>
                            <Typography sx={{ fontSize: 14, mb: 2 }}>
                                Unaalikwa kupendekeza mtu/watu/taasisi wanaofaa
                                kupokea tuzo za ICN katika vipengele tajwa
                                (polling link shared), mtu mmoja kwa kila
                                kipengele. Watakao pendekezwa zaidi watatunukiwa
                                zawadi zao mwezi wa 7 2024 baada ya mchakato
                                kukamilika.
                            </Typography>
                            <Typography sx={{ fontSize: 14, mb: 2 }}>
                                Asante sana kwa kushiriki na pia mtumie mwingine
                                ujumbe huu.
                            </Typography>
                            <Typography sx={{ fontSize: 14, mb: 2 }}>
                                ICN International Award (bringing back the lost
                                art of honor)
                            </Typography>
                        </Grid>
                        {/* <Grid item sm={12} xs={11}>
                            <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSdgCcRHsYpmuQgaHCaiFvvQ49VjFDPNUIy138rqLXNJCpWoWQ/viewform?usp=sf_link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        borderRadius: `50px !important`,
                                        pr: 10,
                                        my: 5,
                                        pl: 5,
                                        py: 1.5,
                                        boxShadow: `0px 0px 5px ${colors.secondary}`,
                                        border: `1px double ${colors.secondary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        JINSI YA KUPIGA KURA BOFYA LINK HII HAPA
                                    </Typography>
                                    <ArrowCircleRightOutlined
                                        sx={{
                                            position: "absolute",
                                            right: 15,
                                            fontSize: 35,
                                            color: colors.secondary,
                                        }}
                                    />
                                </Button>
                            </a>
                        </Grid> */}
                    </Grid>
                </Section>
            </SpacingContent>
        </>
    );
};

export default UpcomingEventsDetailsPage;
