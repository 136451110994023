import { useEffect } from "react";
import { errorNotify, successNotify } from "../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../helpers/RTKHelpers";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetPublicGallariesQuery } from "../api/gallaryAPI/gallaryAPI";
import { setGallaries } from "../state/features/gallaries/gallariesSlice";
import { systemConfigSelector } from "../state/features/selectors";
import { disableMainFetch } from "../state/features/systemConfig/systemConfigSlice";

const useRTK = () => {
    // ############# Redux state #############
    const { isMainFetch } = useSelector(systemConfigSelector);

    // => Use RTK Response
    const useRTKResponse = (isSuccess, isError, error, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions && actions(getRTKResponseData(data));
                    successNotify(getRTKSuccessMessage(data));
                }
                if (isError) {
                    errorNotify(
                        !isEmpty(error)
                            ? getRTKErrorMessage(error)
                            : "Some error occur. Please try again!"
                    );
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, isError, error, data]);
    };

    // => Use RTK Fetch
    const useRTKFetch = (isSuccess, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions(getRTKResponseData(data));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, data]);
    };

    // => RTK main data
    const useRTKMainData = () => {
        // => CONST
        const dispatch = useDispatch();

        // ################# Galleries #################
        const [
            getGallaries,
            {
                isLoading: gallaryLoading,
                isSuccess: gallarySuccess,
                data: gallaryData,
            },
        ] = useLazyGetPublicGallariesQuery();
        const rtkGallaryActions = (data) => {
            dispatch(setGallaries(data));
        };
        useRTKFetch(gallarySuccess, gallaryData, rtkGallaryActions);

        // ############## useEffect #################
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isMainFetch) {
                    getGallaries();
                    setTimeout(() => {
                        dispatch(disableMainFetch());
                    }, 2000);
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, []);

        return gallaryLoading ? gallaryLoading : false;
    };

    return {
        useRTKFetch,
        useRTKResponse,
        useRTKMainData,
    };
};

export default useRTK;
