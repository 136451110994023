import React from 'react';
import Section from '../components/Section';
import SpacingContent from '../components/SpacingContent';
import PageLayout from './layouts/PageLayout';
import ServicedetailsWidget from '../components/widgets/ServicedetailsWidget';
import planning from '../assets/media/images/services/planning.jpeg';
import manage from '../assets/media/images/services/manage.jpg';
import onsite from '../assets/media/images/services/onsite.jpg';
import mc from '../assets/media/images/services/mc.jpeg';

const Services = () => {
    const services = [
        {
            id: 1,
            service: 'Pre-event Planning',
            imgOrder: { md: 1, xs: 1 },
            contentOrder: { md: 2, xs: 2 },
            image: planning,
            description:
                'We organize long lasting memories creating real emotional and physical experiences for everyone. We offer complete events planning and management solutions.',
            subServices: [
                {
                    id: 1,
                    service: 'Budget creation & management',
                    icon: '',
                },
                {
                    id: 2,
                    service: 'Branding creation and custom marketing strategy',
                    icon: '',
                },
                {
                    id: 3,
                    service: 'Develop event theme-based sponsorship packages',
                    icon: '',
                },
                {
                    id: 4,
                    service:
                        'Event Venue Scouting, booking and contract negotiation',
                    icon: '',
                },
                {
                    id: 5,
                    service:
                        'Detailed critical path and timelines for your event',
                    icon: '',
                },
                {
                    id: 6,
                    service: 'Social media planning',
                    icon: '',
                },
            ],
        },
        {
            id: 2,
            service: 'Event Management, Design & Marketing',
            imgOrder: { md: 2, xs: 1 },
            contentOrder: { md: 1, xs: 2 },
            image: manage,
            description:
                'We offer unique approach to event management industry, understanding that a properly executed event  is leveraged to support an organization strategic vision.  We approach every event with attention to details.',
            subServices: [
                {
                    id: 1,
                    service: 'Online registration set up and management',
                    icon: '',
                },
                {
                    id: 2,
                    service: 'Speaker and sponsor management',
                    icon: '',
                },
                {
                    id: 3,
                    service: 'Floor plan layout and decor',
                    icon: '',
                },
                {
                    id: 4,
                    service:
                        'List of local professionals to make your event extra special:',
                    subService: [
                        {
                            id: 1,
                            services: 'Entertainers, musicians,',
                        },
                        {
                            id: 2,
                            services:
                                'Graphic designers, documentation & photographers,',
                        },
                        {
                            id: 3,
                            services: 'Caterers, florists, etc',
                        },
                        {
                            id: 4,
                            services:
                                'Marketing experts, rental companies, etc.',
                        },
                    ],
                    icon: '',
                },
                {
                    id: 5,
                    service: 'Tear down and clean-up of your event',
                    icon: '',
                },
                {
                    id: 6,
                    service: 'Post event summary and follow up',
                    icon: '',
                },
            ],
        },
        {
            id: 3,
            service: 'Onsite Management & Post Event',
            imgOrder: { md: 1, xs: 1 },
            contentOrder: { md: 2, xs: 2 },
            image: onsite,
            description:
                'We learn about your organization and plan events to support your goal. We manage every event with the same dedication, integrity and passion.',
            subServices: [
                {
                    id: 1,
                    service: 'Onsite set up and day-of management',
                    icon: '',
                },
                {
                    id: 2,
                    service: 'Detailed timelines, & MC scripts',
                    icon: '',
                },
                {
                    id: 3,
                    service: 'Friendly and trusting staff',
                    icon: '',
                },
                {
                    id: 4,
                    service: 'Stage management',
                    icon: '',
                },
                {
                    id: 5,
                    service: 'Tear down and clean-up of your event',
                    icon: '',
                },
                {
                    id: 6,
                    service: 'Post event summary and follow up',
                    icon: '',
                },
            ],
        },
        {
            id: 4,
            service: 'Master of ceremony (MC)',
            imgOrder: { md: 2, xs: 1 },
            contentOrder: { md: 1, xs: 2 },
            image: mc,
            description:
                'Talent, creativity and ability to create a memorable event experience, as we believe in upholding the tradition and enjoy it to the fullest. We host all sorts of events like :',
            subServices: [
                {
                    id: 1,
                    service: 'Fundraising',
                    icon: '',
                },
                {
                    id: 2,
                    service: 'Anniversaries ',
                    icon: '',
                },
                {
                    id: 3,
                    service: 'Awards',
                    icon: '',
                },
                {
                    id: 4,
                    service: 'Weddings & Engagements',
                    icon: '',
                },
                {
                    id: 5,
                    service: 'Birthday parties',
                    icon: '',
                },
                {
                    id: 6,
                    service: 'Book Launces ',
                    icon: '',
                },
                {
                    id: 7,
                    service: 'Annual Conferences and Symposium',
                    icon: '',
                },
                {
                    id: 8,
                    service: 'Exhibitions & Trade Fair ',
                    icon: '',
                },
                {
                    id: 9,
                    service: 'Tour & adventure Events ',
                    icon: '',
                },
                {
                    id: 10,
                    service: 'Album launch ',
                    icon: '',
                },
                {
                    id: 11,
                    service: 'Grand openings ',
                    icon: '',
                },
                {
                    id: 12,
                    service: 'Cooperate Events',
                    icon: '',
                },
            ],
        },
    ];

    return (
        <>
            <PageLayout
                head={'Our services'}
                subHead={
                    'Pre-event Planning | Event Management, Design & Marketing | Onsite Management & Post Event | Master of ceremony (MC)'
                }
            >
                <SpacingContent>
                    <Section>
                        {services?.map((service) => (
                            <ServicedetailsWidget
                                service={service}
                                key={service.id}
                            />
                        ))}
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default Services;
