import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';

const EventTypeWidget = ({ event }) => {
    return (
        <>
            <Box sx={{ borderRadius: 10 }}>
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor6}, ${colors.bgColor3})`,
                        p: 1,
                        borderTopRightRadius: 25,
                        borderTopLeftRadius: 25,
                    }}
                >
                    <Avatar
                        src={event.image}
                        variant="square"
                        sx={{
                            width: '100%',
                            height: 180,
                            // mt: -5,
                            borderRadius: 8,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        height: 80,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.primary})`,
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        borderBottomRightRadius: 25,
                        borderBottomLeftRadius: 25,
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            width: '15%',
                            height: 80,
                            borderTopRightRadius: 25,
                            borderBottomRightRadius: 25,
                            background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.primary})`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRight: `5px double ${colors.secondary}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                color: colors.secondary,
                            }}
                        >
                            {event.id}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '85%', px: 2 }}>
                        <Typography sx={{ fontSize: 15 }}>
                            {event.event}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default EventTypeWidget;
