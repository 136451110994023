// => LOGIN
const LOGIN = '';

// => LOGOUT
const LOGOUT = '';

export const authEndpoints = {
    LOGIN,
    LOGOUT,
};
