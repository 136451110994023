import React from 'react';
import Section from './Section';
import {
    Avatar,
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import SpacingContent from './SpacingContent';
import EventWidget2 from './widgets/EventWidget2';
import { colors } from '../assets/utils/colors';
import { ArrowRightAltRounded } from '@mui/icons-material';
import service from '../assets/media/images/service.JPG';
import { Link } from 'react-router-dom';

const OurServices = () => {
    const deviceWidth = useMediaQuery(`(min-width:768px)`);

    const services = [
        {
            id: 1,
            service: 'Pre-event Planning',
        },
        {
            id: 2,
            service: 'Event Management, Design & Marketing',
        },
        {
            id: 3,
            service: 'Onsite Management & Post Event',
        },
        {
            id: 4,
            service: 'Master of ceremony (MC)',
        },
    ];

    return (
        <>
            <SpacingContent>
                <Section
                    head={'Our services'}
                    subHead={'Onsite Management & Post Event'}
                >
                    <Grid container spacing={4} justifyContent={'center'}>
                        <Grid item sm={5} xs={11} sx={{ mt: { md: 0, xs: 3 } }}>
                            <Box
                                sx={{
                                    'position': 'relative',
                                    ':before': {
                                        content: "''",
                                        height: { md: 400, xs: 250 },
                                        width: { md: 150, xs: 25 },
                                        position: 'absolute',
                                        left: -20,
                                        top: -20,
                                        borderTop: `10px solid ${colors.bgColor6}`,
                                        borderLeft: `10px solid ${colors.bgColor6}`,
                                        borderTopLeftRadius: { md: 110, xs: 0 },
                                    },
                                }}
                            >
                                <Avatar
                                    src={service}
                                    variant="square"
                                    sx={{
                                        width: '100%',
                                        height: { md: 500, xs: 250 },
                                        borderTopLeftRadius: deviceWidth
                                            ? 100
                                            : 0,
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={7}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1,
                                    px: 4,
                                }}
                            >
                                <Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: { md: 20, xs: 18 },
                                                textTransform: 'uppercase',
                                                fontWeight: 'bold',
                                                color: colors.secondary,
                                                textAlign: {
                                                    md: 'left',
                                                    xs: 'center',
                                                },
                                            }}
                                        >
                                            We keen to provide best services for
                                            you
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 15,
                                                py: 3,
                                                opacity: 0.8,
                                            }}
                                        >
                                            Seeing through each event from start
                                            to finish while keeping the goals,
                                            mission and vision, budget and
                                            client's needs in mind, always. We
                                            are committed to follow through on
                                            every detail, so you don't have to.
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {services.map((service) => (
                                            <EventWidget2
                                                service={service}
                                                key={service.id}
                                            />
                                        ))}
                                    </Box>
                                    <Box>
                                        <Link to={'/services'}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    'py': 1,
                                                    'px': 4,
                                                    'fontWeight': 'bold',
                                                    'fontSize': {
                                                        md: 14,
                                                        xs: 12,
                                                    },
                                                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                                    'transition': `.5s all ease-in-out`,
                                                    ':hover': {
                                                        transform: `scale(1.01)`,
                                                        transition: `.5s all ease-in-out`,
                                                    },
                                                }}
                                                endIcon={
                                                    <ArrowRightAltRounded />
                                                }
                                            >
                                                Learn more about our services
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Section>
            </SpacingContent>
        </>
    );
};

export default OurServices;
